/*CSS to remove border from Semantic UI table*/
.ui.table tr td {
  border: 0px !important;
  box-shadow: none !important;
}

.ui.table tr td:first-child {
  font-weight: 700;
  color: rgb(163,163,163);
}

.ui.basic.table tbody tr {
  border: 0 !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.ui.table td.collapsing {
  white-space: normal !important;
}

.ui.embed {
  height: 600px !important;
}

#map-container {
    padding: 0 !important;
  }


.recovery-table tr td:first-child {
  font-weight: inherit !important;
  color: inherit !important;
}

.ui.basic.button {
  background: white !important;
}
